body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@font-face {
  font-family: "MyFont";
  src: url(../../static/media/adamfont-webfont.5b903dbe.woff);
}

/* tag */
html,
body {
  height: 100%;
  background-color: #f9c333;
  background-image: url(../../static/media/grain.99e6987b.png);
}
#root {
  height: 100%;
}

a {
  color: inherit;
}

a:visited {
  color: inherit;
  text-decoration: none;
}
a:-webkit-any-link {
  color: inherit !important;
  text-decoration: none !important;
}
a:before {
  color: inherit;
  text-decoration: none;
}
/* class is . */
.image-word {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.active {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAASwAAAAICAYAAABDN8LhAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA+RJREFUeNrsmltPFDEUx9uZ2YFdFBHRiBG8JQgPJnwBv4GPfhs/oM++GQMGJUgQ5bK4992pbfKrOTadlQcS2dAmJzPTyzmn//M/7Ux3tTFG3aCSWcmtjHh295UV848xWjz7e8PYTDxrriYYW3GdiPpqRjDTwXwqlUri4/8io12w3tnrBytdK00rQyu3rZxamWeybkJnXBfp+5G+pQBnzkrPSoFs0Ob6vUavBytHXxdfXNs5PnQZX6LP9V220rLyDaDnqH+PjQvqfln5xHiNjLHbQ/d95tOh3wPaG1YeWTlgXBufrqI4X1fw3fm6IIgyhx8j6sb0e2pl3UofadLWA4uHVjYZn4HDiGuXvkPsOpvH6MvQ4WzfoV/B9Qw8fjD2LbqG4OYw2UXfEZg5+19oO6FvwbxvWdnmegKn+sR7Gz8+o89zIPEx8THKR7dgdSCCEquzXKlHAOfJoFB0KFbwDAMZE/M7x5LYNby+SbBLDLgvCZjfYQpAOBJ6S3wZM3YIOEuCpAqCPBa7iQe+jd+vrOyRZC5QL2lvEYQezy6RvmO3D2htgH4GqVu0jRkzj+9zzK3LvFwgtuh3DEkb6Guha8T4Hv1WBW6yDNAvi08Qn1RD5uYXjnNBLC3iOcRGJuoXRaz3xe7dhkCbglAFyetja8Q8dBCXSsRhINobiH9jSHxMfIzy0S1YW4CZA3IpdrsSZxSOPxEkWRS7TiVALanvMHbM/R5O9gGry7gCeyV1x/hyl0TYQY9f+cMEWAZIv9OvsBNUrMxjYUvTz+0Eb6g7wOYRhFH45Hx4YWUN/T4x7kHWA+asBOhnIsA+ON/pr7HTwu4+GDchng/8uUh2h9VX0d/5/hydbYLpE2AXfev0neBDzv0pOgvGyeRRIhYZCbfGG9GO+Ky4EG9ahdipN+DDQMSmSd0huivi4Ob/E7xzYXdevIEkPiY+Rvmor+EZVvg9LXfp7BLf+Jctq2KlHwmgwuLfILztBeyfigRtEIiOSGTv78WMn//oK8J7ls+ZEh+v0RlWLiZghGSRQJigr6zPIwd8VXBAaALwJuI5TAwdvLZXkUNDubOpGlsqcuBoggPHPIJNJfpXU5JZCbzq7JrgUDW2GOjgE0h+Cpmgv6wLsTA1n2I6QtQYhpmYcxZ51jVckPZ0EFsVtKsaXukIbomPiY9/+OcXLB0JuAyijpBKCwCn7UTT7kNwVQ1Rwl80YqQwNbtfmGAmAEbaqdtJqwgpVA0RdBDYMJlNTZKrgPzycHQSwcSIxKyCuJgaPE3NPMJfk2ILjJkSexWJ5zRdMWLH4pj4mPj4Fwf0DftbQyqppDLD5bcAAwAoso7AgsKSMwAAAABJRU5ErkJggg==);
  background-position: bottom;
  background-repeat: no-repeat;
}

.apostrophe {
  position: relative;
  top: -103px;
}

.app-container {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: trans;
  margin: -19px 0 0 0;
}

.abouttheme {
  background-color: #333333;
}

/* id is # */

