/* tag */
html,
body {
  height: 100%;
  background-color: #f9c333;
  background-image: url(./images/grain.png);
}
#root {
  height: 100%;
}

a {
  color: inherit;
}

a:visited {
  color: inherit;
  text-decoration: none;
}
a:-webkit-any-link {
  color: inherit !important;
  text-decoration: none !important;
}
a:before {
  color: inherit;
  text-decoration: none;
}
/* class is . */
.image-word {
  display: flex;
  flex-direction: row;
}

.active {
  background-image: url(./images/menu/active-icon.png);
  background-position: bottom;
  background-repeat: no-repeat;
}

.apostrophe {
  position: relative;
  top: -103px;
}

.app-container {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: trans;
  margin: -19px 0 0 0;
}

.abouttheme {
  background-color: #333333;
}

/* id is # */
